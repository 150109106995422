<template>
<div>
  <h5 class="h3 mb-0">{{getTrans('messages.password')}}</h5>
  <form>
    <div class="row">
      <div class="col">
        <base-input
          v-model="currentPassword"
          :error="getError('old_password')"
          type="password"
          autocomplete="current-password"
          :label="getTrans('messages.current_password')"
          id="current-password"
        />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <base-input
          v-model="newPassword"
          :error="getError('password')"
          type="password"
          autocomplete="new-password"
          :label="getTrans('messages.new_password')"
          id="new-password"
        />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <base-input
          v-model="confirmPassword"
          :error="getError('password_confirmation')"
          type="password"
          autocomplete="repeat-password"
          :label="getTrans('messages.repeat_new_passw')"
          id="repeat-password"
        />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <base-button type="primary" @click="saveUser">{{getTrans('messages.save')}}</base-button>
      </div>
    </div>
  </form>
</div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      currentPassword:"",
      newPassword:"",
      confirmPassword:"",
    };
  },
  props: {
    getError:Function,
  },
  methods: {
    saveUser() {
      let u = {
        old_password: this.currentPassword,
        password: this.newPassword,
        password_confirmation: this.confirmPassword,
      }
      this.$emit("save", u);
    },
    clearInput() {
      this.currentPassword = "";
      this.newPassword = "";
      this.confirmPassword = "";
    }
  }
};
</script>
